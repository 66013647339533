import React, { Component } from 'react';

class Welcome extends Component {
  render() {
    return (
      <div className="Welcome">
        <p>This is your public-facing component.</p>
      </div>
    );
  }
}
export default Welcome;
