import React, { Component } from 'react';
import Keycloak from 'keycloak-js';
import UserInfo from './UserInfo';
import Logout from './Logout';

class Secured extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      keycloak: null, 
      authenticated: false,
      countries: []
    };
  }

  componentDidMount() {
    const keycloak = Keycloak('/keycloak.json');
    keycloak.init({onLoad: 'login-required'}).then(authenticated => {
      this.setState({ keycloak: keycloak, authenticated: authenticated })
    })

    fetch('https://rest.api.eti.br/country', {headers: {'Authorization': `Bearer ${localStorage.getItem("react-token")}`}})
      .then((response) => response.json())
      .then(countryList => {
        this.setState({ countries: countryList })
      })
  }

  render() {
    if(this.state.keycloak) {
      localStorage.setItem("react-token", this.state.keycloak.token)
      localStorage.setItem("react-refresh-token", this.state.keycloak.refreshToken)

      if(this.state.authenticated) return (
        <div>
          <p>Thiss is a Keycloak-secured component of your application. You shouldn't be able
          to see this unless you've authenticated with Keycloak.</p>

          <ul>
            {this.state.countries.items.map((country) => (
              <li key={country._id}>{country.name}</li>
            ))}
          </ul>
          <UserInfo keycloak={this.state.keycloak} />
          <Logout keycloak={this.state.keycloak} />
        </div>
      ); else return (<div>Unable to authenticate!</div>)
    }
    return (
      <div>Initializing Keycloak...</div>
    );
  }
}
export default Secured;
